.calendar{
    &-heading{
      border-bottom: 1px solid --var(light-2);
      // margin-bottom: 1.5rem;
    }
    &-display {
      align-items: center;
      @media (max-width: 560px) {
        width: 80%;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        // border: 1px solid red;
        // flex-direction: column; 
      }

      .day-dates {
        appearance: none;
        background-color: white;
        font-size: 1.6rem;
        padding: 1rem;
        border: 2px solid white;
        padding-bottom: 0em;
        @media (max-width: 560px) {
          flex-direction: row;
          justify-content: space-between;
          gap: .5rem;
        }
  
        &-container {
      
          @media (max-width: 560px) {
            flex-direction: column; 
          }
        }
        &:hover{
          border-bottom: 2px solid #F7931E;
        }
        cursor: pointer;
      }
      
      .focusDay p {
        font-weight: 600;
        color: #F7931E !important;
      }
      .date-label {
        font-weight: bold;
      }
      .isToday {
        color: #F7931E !important;
      }
  }
  }