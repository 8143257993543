h3.table-caption {
    text-transform: inherit;
    font-weight: normal;
}
.rundown_awayteamnames { width:15%; }
.rundown_hometeamnames { width:20%; }
.rundown_scheduletimeevent { width:20%; }
.rundown_schedulelocation { width:25%; }
.rundown_schedule_at { padding:0px 15px 0px 0px; }

.sticky_columns {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 100;
    text-align: left;
    padding: 0 12px;
    background: #F7F8FD;
}

.odd_spread_table tr th {
	background: #ececec !important;
	color: #333 !important;
}
.odd_spread_table tr th:first-child {
	/*border-left:2px solid #F7931E;*/
}
.odd_spread_table tr th:last-child {
	/*border-right:2px solid #F7931E;*/
}
.odd_cs_table tr td:first-child {
    border-left: none !important;
   /* border-bottom: 2px solid #aeaeae;*/
}
.daterowintable td {
	background: #ececec !important;
	color: #333 !important;
}
.odd_cs_table th {
    border-bottom: 2px solid #333;
}
.odd_spread_table tr td {
    color: #000;
	font-weight:600;
}
.odd_row_td_in_data td, .even_row_td_in_data td {
    background: #fff; vertical-align:middle;
}
td.spread_time {
    vertical-align: top;
}
.odd_row_td_in_data td:first-child {
    border-left: 2px solid #aeaeae;
   /* border-bottom: 2px solid #aeaeae;*/
}
.odd_row_td_in_data td:last-child, .even_row_td_in_data td:last-child {
    border-right: 2px solid #aeaeae;
}
tr.last_row_tr_in_data td {
    border-bottom: 2px solid #aeaeae;
    /*border-left: 2px solid #aeaeae;
    border-right: 2px solid #aeaeae;*/
    border-top: 0px solid #aeaeae;
}

.odd_cs_table tr th .fa-angle-left, .odd_cs_table tr th .fa-angle-right
{
    height: 36px;
    margin-top: -6px;
    position: absolute;
	cursor:pointer;
}
.odd_cs_table tr th .fa-angle-left
{
    right: 85px;
}
.odd_cs_table tr th .fa-angle-right
{
    right: 50px;
}

.last_row_of_date_tr_in_data {
    border-bottom: 2px solid #aeaeae;
    border-left: 2px solid #aeaeae;
    border-right: 2px solid #aeaeae;
}

.title_row_tr_in_data td {
    border-bottom: 2px solid #aeaeae;
}
td.spread_affliates {
    vertical-align: middle; text-align:center;
}
.table_before_div { overflow-x:hidden; overflow-y: hidden; margin-top:20px; }
.even_row_td_in_data ul:first-child { display:none; }
.spread_time ul:first-child {
    margin-bottom: 10px;
}

.div_table_schedule_overflow { overflow:auto;; }
.table_schedule_overflow { width:1100px; }

.table_before_div_score { max-width:100%; }

table.odd_spread_table td, table.odd_spread_table th {
    padding: 10px !important;
}
table.odd_cs_table th {
    padding: 25px 10px !important;
}
tr.borderbottomintd {
    border-bottom: 2px solid #aeaeae;
}
.winner_arrow_html {
    position: absolute;
    right: -2px;
    top: 35%;
}
.winner_arrow_html:before {
    font-size: 20px;
}
	
td.spread_time.hide_border_bottom {
    border-bottom: 2px solid #fff;
}
span.hide_spread_time {
    display: none;
}
span.hide_spread_rotation {
    display: none;
}
.hide_td_span_data { display:none; }
.hidethisrowfromtable { display:none; }

span.search_odd_section {
    margin: 0px 20px;
}
span.search_odd_section label {
    font-size: 18px;
    color: #000;
}

.spread_time { width:90px; }
.odd_cs_table .spread_time { width:210px; }
.timeofevent { font-size:12px; }
.spread_rotation { width:25px; }
.spread_teamnames { width:85px; }
.spread_teamscore {
    width: 30px;
    position: relative;
    text-align: center;
}
.spread_teamscore span {
    border: 1px solid #f7931e;
    /* padding: 2px; */
    width: 100%;
    display: inline-block;
}
.spread_open { width:30px;}
.spread_teamimg {
    width: 70px;
    vertical-align: middle !important;
}
span.team_score {
    font-size: 12px;
}
span.team_abb {
    font-weight: bold;
    font-size: 16px;
}

.spread_team { width:70px; }
.spread_score { width:70px; }
.spread_affliates { width:80px; }
.winnerteaminfo {
    font-size: 18px;
    font-weight: bold;
}

.affliates_span_data {
    /*background: #5a5a5a;
    padding: 2px 2px;
    color: #fff;
    min-width: 60px;
    display: inline-block;
    text-align: center;*/
	font-size:13px;
}
.affliates_span_data b { font-size:15px; }

.affliates_span_data.affliates_span_data_null {
    background: #EBECF5;
    color: #A4A4AA;
}
img.team_img_icon_schedule {
    width: 20px;
    margin: -2px 5px 0px 0px;
}
img.team_img_icon_odd {
    width: 48px;
    margin: 5px 0px 0px 0px;
}
table.odd_cs_table tr td:first-child, table.odd_cs_table tr th:first-child {
    box-shadow: inset -2px 0px 0px 0px #aeaeae;
}
@media (max-width:999px){
	.row-50-sm { width: 50%; }
	.row-button-sm { text-align: center !important; margin-top: 10px; }
}