// * {
//     padding: 0;
//     margin: 0;
//     font-family: sans-serif;
//     box-sizing: border-box;
//   }
//   html {
//     // font-size: 62.5%; //10px = 1rem
//   }
  .d-flex {
    display: flex;
  }
  .flex-col {
    flex-direction: column;
  }
  .align-center {
    align-items: center;
  }
  .j-center {
    justify-content: center;
  }
  .mt-2 {
    margin-top :2rem;
  }
  .gap-1 {
    gap: 1rem;
  }
  .j-between {
    justify-content: space-between;
  }
  body {
    background-color: var(--light-1);
  }
  // .app {
    
  //   border: 1px solid var(--light-2);
  //   width: 100%;
  //   max-width: 102.4rem;
  //   height: 100%;
  //   margin: 0 auto;
  //   padding: 2rem;   
  
  // }